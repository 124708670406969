import React from 'react';
import { View, Text, Button, Title, P, Link } from '../components/common';
import Layout from '../components/layout';
import Content, { SMALL } from '../components/content';
import PlaceholderImage from '../components/PlaceholderImage';

export default () => (
  <Layout>
    <Content
      style={{
        width: 700,
        [SMALL]: { width: 'auto', padding: 15 }
      }}
    >
      <Title title="Migrating old budgets" />
      <P>
        If you are here, it's because you have been a user of Actual for a long
        time. Thank you! Some changes were made in recent version so that older
        budgets couldn't be opened.
      </P>

      <P>
        You need to "upgrade" your budget by downloading version 0.0.87 (
        <Link external to="https://jlongster.com/s/Actual-0.0.87.dmg">
          macOS
        </Link>
        ,{' '}
        <Link external to="https://jlongster.com/s/Actual-Setup-0.0.87.exe">
          Windows
        </Link>
        ) and open your budget. Then you need to download 0.0.94 (
        <Link
          external
          to="https://github.com/actualbudget/releases/releases/download/0.0.94/Actual-0.0.94.dmg"
        >
          macOS
        </Link>
        ,{' '}
        <Link
          external
          to="https://github.com/actualbudget/releases/releases/download/0.0.94/Actual-Setup-0.0.94.exe"
        >
          Windows
        </Link>
        ) and open your budget with that version. After those 2 upgrades, your
        budget should be upgraded and work on all newest versions.
      </P>
    </Content>
  </Layout>
);
